import moment from "moment";

export function dateToMonthString(inputDate) {
    if(inputDate == '') return '';
    
    const date = moment(inputDate)
    try {
        return date.format('DD MMM YYYY').toUpperCase();
    } catch(e) {
        return '';
    }
}
