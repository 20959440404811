<template>
    <section id="dashboard-import-register" class="position-relative">
        <loading v-if="loading.import" />
        <b-card no-body>
            <b-card-body class="p-5 body-grow d-flex flex-column">
                <h3 class="text-left text-secondary font-weight-bolder">
                    Importação de Cadastros
                </h3>

                <div
                    class="d-flex align-items-center justify-content-between flex-wrap my-3 text-left"
                >
                    <h6 class="text-dark font-weight-bold m-0">
                        Para realizar uma importação realize os seguintes passos
                    </h6>
                    <div class="d-flex align-items-center">
                        <i>Formato do Telefone: DDI+DDD+TELEFONE</i>
                        <font-awesome-icon
                            v-b-tooltip.hover.left="
                                'Telefones precisam seguir o padrão indicado contendo DDI e DDD. Exemplos: +55 (21) 94444-4444, 5521944444444'
                            "
                            class="ml-2"
                            icon="fas fa-circle-info"
                        />
                    </div>
                </div>

                <div class="d-flex align-items-center">
                    <span
                        class="mr-3 rounded-circle bg-primary d-flex align-items-center justify-content-center text-white font-weight-bold step"
                    >
                        1
                    </span>
                    <h6 class="text-dark text-left font-weight-bold my-3">
                        Formato do arquivo para importação
                    </h6>
                </div>

                <div class="d-flex flex-column flex-md-row ml-md-5 my-2">
                    <div class="flex-grow-1 mb-2">
                        <b-form-select
                            v-model="formSelected"
                            @click="getForms()"
                        >
                            <template #first>
                                <b-form-select-option :value="null">
                                    Selecione o formulário
                                </b-form-select-option>
                            </template>

                            <b-form-select-option
                                v-if="loading.forms"
                                :value="null"
                                disabled
                            >
                                Carregando...
                            </b-form-select-option>

                            <b-form-select-option
                                v-for="(form, index) in forms"
                                :key="`select--form--item--${index}`"
                                :value="form"
                            >
                                {{ form.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="ml-2">
                        <router-link
                            v-slot="{ navigate }"
                            :to="{ name: 'forms/new' }"
                            custom
                        >
                            <button
                                class="btn btn-outline-primary flex-grow-1"
                                @click="navigate"
                            >
                                Criar novo formulário
                            </button>
                        </router-link>
                    </div>
                </div>
                <div
                    class="col-12 text-center text-md-left col-md-8 ml-md-5 p-0"
                >
                    Download do formulário
                    <a
                        class="text-blue text-decoration-underline"
                        href="#download"
                        @click.prevent="downloadForm()"
                    >
                        aqui
                    </a>
                </div>

                <div class="d-flex align-items-center mt-4">
                    <span
                        class="mr-3 rounded-circle bg-primary d-flex align-items-center justify-content-center text-white font-weight-bold step"
                    >
                        2
                    </span>
                    <h6 class="text-dark text-left font-weight-bold my-3">
                        Selecione o número de disparo que será adicionado aos
                        clientes.
                    </h6>
                </div>
                <div class="d-flex flex-column flex-md-row ml-md-5 my-2">
                    <div class="flex-grow-1 mb-2">
                        <b-form-select
                            v-model="phoneSelected"
                            @click="getPhones()"
                        >
                            <template #first>
                                <b-form-select-option :value="null">
                                    Selecione o número de disparo
                                </b-form-select-option>
                            </template>

                            <b-form-select-option
                                v-if="loading.phones"
                                :value="null"
                                disabled
                            >
                                Carregando...
                            </b-form-select-option>

                            <b-form-select-option
                                v-for="(phone, index) in phones"
                                :key="`select--phone--item--${index}`"
                                :value="phone"
                            >
                                {{ phone.name }} - {{ phone.phone }}
                            </b-form-select-option>
                        </b-form-select>
                    </div>
                </div>

                <div class="d-flex align-items-center mt-4">
                    <span
                        class="mr-3 rounded-circle bg-primary d-flex align-items-center justify-content-center text-white font-weight-bold step"
                    >
                        3
                    </span>
                    <h6 class="text-dark text-left font-weight-bold my-3">
                        Clique no botão a baixo ou arraste para dentro da área.
                    </h6>
                </div>

                <div
                    id="dropzone--import"
                    class="ml-md-5 p-4 overflow-hidden my-2 flex-grow-1 rounded border border-secondary d-flex flex-column align-items-center justify-content-center position-relative"
                    @drop.prevent="handleDropFile"
                    @dragleave.prevent="handleChangeStyle(false)"
                    @dragover.prevent="handleChangeStyle(true)"
                >
                    <div
                        v-if="files.length > 0"
                        class="w-100 my-2 flex-wrap row justify-content-center"
                    >
                        <div
                            v-for="(file, index) in files"
                            :key="`file--card--item--${index}`"
                            class="file--card m-2 d-flex flex-column rounded col-6 col-sm-4 col-md-3 col-lg-2 px-1"
                        >
                            <div class="file--header text-right py-1">
                                <button
                                    class="file--remove rounded-circle"
                                    @click.prevent="handleRemoveFile(index)"
                                >
                                    <font-awesome-icon icon="fas fa-xmark" />
                                </button>
                            </div>
                            <div class="file--icon">
                                <font-awesome-icon icon="fas fa-file" />
                            </div>
                            <div
                                class="file--footer d-flex flex-column align-items-center justify-content-center h-100"
                            >
                                <p class="m-0">{{ file.name }}</p>
                            </div>
                        </div>
                    </div>

                    <label
                        v-if="files.length < 1"
                        class="btn btn-primary dropzone--hide m-0 my-2"
                        for="add--file--to--import"
                    >
                        Clique Aqui ou Arraste o Arquivo
                    </label>

                    <div class="d-none dropzone--placeholder">
                        <div>
                            <span>Solte para adicionar o arquivo</span>
                        </div>
                    </div>

                    <input
                        id="add--file--to--import"
                        accept=".xlsx"
                        hidden
                        type="file"
                        @change.prevent="handleAddFiles"
                    />
                </div>

                <div
                    class="row align-items-center justify-content-between mt-2"
                >
                    <span
                        v-if="$role(['marks.create'])"
                        v-b-modal.addMarkModal
                        class="text-secondary text-decoration-underline ml-2 col-12 col-md-6 col-lg-4"
                        role="button"
                    >
                        Criar um novo marcador
                    </span>
                    <div class="col-12 col-md-6 col-lg-4 d-flex">
                        <button
                            class="btn btn-outline-primary flex-grow-1 mr-2"
                            @click.prevent="goToHome"
                        >
                            Cancelar
                        </button>
                        <button
                            class="btn flex-grow-1 btn-primary"
                            @click.prevent="handleSubmit"
                        >
                            Salvar Padrão
                        </button>
                    </div>
                </div>
            </b-card-body>
        </b-card>
        <color-picker-modal />
    </section>
</template>

<script>
import {
    BCard,
    BCardBody,
    BFormSelect,
    BFormSelectOption,
} from "bootstrap-vue";
import api from "@/api";
import { dateToMonthString } from "@/libs/utils";
import Loading from "@/views/components/Loading";
import ColorPickerModal from "@/views/pages/dashboard/messages/modals/AddMarkModal";

export default {
    components: {
        BCard,
        BCardBody,
        BFormSelect,
        BFormSelectOption,
        Loading,
        ColorPickerModal,
    },
    data() {
        return {
            formsLoading: false,
            formSelected: null,
            phoneSelected: null,
            files: [],
            forms: [],
            phones: [],
            loading: {
                forms: false,
                import: false,
                phones: false,
            },
        };
    },

    created() {
        this.getForms();
        this.getPhones();
    },
    methods: {
        dateToMonthString,
        clearForm() {
            this.phoneSelected = null;
            this.formSelected = null;
            this.files = [];
        },
        goToHome() {
            this.$swal({
                title: "Operação cancelada",
                icon: "info",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                this.$router.replace({ name: "register" });
            });
        },
        handleSubmit() {
            if (this.files.length === 0) {
                return this.$swal(
                    "É necessário que um arquivo seja adicionado",
                    "Adicione uma planilha contendo os dados.",
                    "error"
                );
            }
            this.loading.import = true;

            const formData = new FormData();
            formData.append("file", this.files[0]);
            if (this.formSelected != null)
                formData.append("form_id", this.formSelected._id);

            if (this.phoneSelected != null)
                formData.append("phone_id", this.phoneSelected._id);

            api.post(`/customers/import`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((response) => {
                    const { type, message, errors, body } = response.data;
                    if (type === "success") {
                        this.clearForm();

                        if (body.batch)
                            this.$root.$emit("pushWatchImport", body);
                    }

                    const errs = body && body.errors ? body.errors : errors;
                    const msg = message || "Houve um erro ao importar os dados";

                    if (errs.length == 0) return;

                    return this.$swal({
                        html: `<strong>${msg}</strong><br/><small>${errs.join(
                            "<br/>"
                        )}</small>`,
                        icon: type,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        const { message, errors } = error.response.data;
                        return this.$swal(message, errors[0], "error");
                    }
                    this.$swal(
                        "Não foi possível importar dados",
                        "Houve um erro inesperado, tente novamente em alguns minutos.",
                        "error"
                    );
                })
                .finally(() => (this.loading.import = false));
        },
        handleDropFile(event) {
            this.handleChangeStyle(false);
            if (event.dataTransfer.items) {
                for (var i = 0; i < event.dataTransfer.items.length; i++) {
                    if (event.dataTransfer.items[i].kind === "file") {
                        const file = event.dataTransfer.items[i].getAsFile();
                        this.addFile(file);
                    }
                }
            } else {
                for (let b = 0; i < event.dataTransfer.files.length; b++) {
                    this.addFile(event.dataTransfer.files[b]);
                }
            }
        },
        handleChangeStyle(isHover) {
            const div = document.getElementById("dropzone--import");
            if (isHover) return div.classList.add("dropzone");

            return div.classList.remove("dropzone");
        },
        handleAddFiles(event) {
            for (let i = 0; i < event.target.files.length; i++) {
                this.addFile(event.target.files[i]);
            }
            event.target.value = "";
        },
        handleRemoveFile(index) {
            this.files.splice(index, 1);
        },
        addFile(file) {
            const acceptedExt = ["xlsx"];
            if (!acceptedExt.includes(file.name.split(".").pop())) {
                return this.$swal({
                    title: "Arquivo inválido",
                    text: "Somente planilhas em excel são aceitas (.xlsx).",
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#317f98",
                });
            }

            if (this.files.length > 0) {
                return this.$swal({
                    title: "Muitos arquivos",
                    text: "Somente um arquivo pode ser enviado.",
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#317f98",
                });
            }

            this.files.push(file);
        },
        getForms() {
            this.loading.forms = true;

            this.$store
                .dispatch("form/index", {
                    auth: this.auth,
                })
                .finally(() => {
                    this.forms = this.$store.getters["form/get"];
                    this.loading.forms = false;
                });
        },
        getPhones() {
            if (this.phones.length === 0) {
                this.loading.phones = true;

                api.get("/phones")
                    .then((response) => {
                        const { type, body } = response.data;

                        if (type !== "success") return;
                        body.map((phone) => this.phones.push(phone));
                    })
                    .finally(() => {
                        this.loading.phones = false;
                    });
            }
        },
        downloadForm() {
            const formId =
                this.formSelected == null ? "default" : this.formSelected._id;

            this.$swal({
                title: "Preparando arquivo...",
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    this.$swal.showLoading();
                },
            });

            api.get(`/forms/export/${formId}`, { responseType: "blob" })
                .then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {
                            type: "application/xlsx",
                        });
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = "planilha-de-formulario.xlsx";
                        link.click();
                        URL.revokeObjectURL(link.href);
                        return this.$swal.close();
                    }
                    throw new Error("");
                })
                .catch(() => {
                    this.$swal({
                        title: "Arquivo indísponível",
                        text: "Não foi possível realizar o download do arquivo.",
                        icon: "error",
                        confirmButtonText: "Continuar",
                        confirmButtonColor: "#317f98",
                    });
                });
        },
    },
};
</script>
